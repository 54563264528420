import {kinguin,instant,steam,h_red,g2a,eneba,cdk,greenMan,fanatic,gog,epic, ubi} from "./imports";

const items = [
    {
      id: 1,
      url: "https://store.ubisoft.com/ie/home?lang=en-ZW",
      color: "#0164F3",
      logo: {
        name: "ubi50.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "ubi50_c7d75fe3eb",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 3.71,
        imgurl: ubi,
      },
    },
    {
      id: 2,
      url: "https://store.epicgames.com/en-US/",
      color: "#3478EA",
      logo: {
        name: "Epic_Games_logo 1.svg",
        width: 50 ,
        height: 50,
        formats: null,
        hash: "Epic_Games_logo_1_d3a325eb8c",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 25.8,
        // size: 3.71,
        imgurl: epic,
      },
    },
    {
      id: 3,
      url: "https://store.steampowered.com/",
      color: "#7EBFF0",
      logo: {
        name: "steam 1.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "steam_1_259a54d600",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 3.54,
        imgurl: steam,
      },
    },
    {
      id: 4,
      url: "https://www.humblebundle.com/",
      color: "#C9272C",
      logo: {
        name: "H-Red.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "H_Red_46e6a2a2e4",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 0.87,
        imgurl: h_red,
      },
    },
    {
      id: 5,
      url: "https://www.g2a.com/",
      color: "#FC7826",
      logo: {
        name: "Logo_g2a_icon 1.svg",
        width: 67,
        height: 50,
        formats: null,
        hash: "Logo_g2a_icon_1_cdd9445417",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 3.98,
        imgurl: g2a,
      },
    },
    {
      id: 6,
      url: "https://www.eneba.com/",
      color: "#4021A5",
      logo: {
        name: "logoFull 1.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "logo_Full_1_ab0076e458",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 3.56,
        imgurl: eneba,
      },
    },
    {
      id: 7,
      url: "https://www.cdkeys.com/",
      color: "#836CBD",
      logo: {
        name: "cdk.svg",
        width: 80,
        height: 50,
        formats: null,
        hash: "cdk_58d8dd4598",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 1.89,
        imgurl: cdk,
      },
    },
    {
      id: 8,
      url: "https://www.kinguin.net/",
      color: "#656565",
      logo: {
        name: "kinguin.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "kinguin_666b7c53ce",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 3.07,
        imgurl: kinguin,
      },
    },
    {
      id: 9,
      url: "https://www.greenmangaming.com/",
      color: "#00E205",
      logo: {
        name: "greenman.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "greenman_cecf012d97",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 0.42,
        imgurl: greenMan,
      },
    },
    {
      id: 10,
      url: "https://www.fanatical.com/en/",
      color: "#FF9800",
      logo: {
        name: "fanatical.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "fanatical_1fdbe40467",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 0.77,
        imgurl: fanatic,
      },
    },
    {
      id: 11,
      url: "https://www.gog.com/en",
      color: "#78387B",
      logo: {
        name: "Logo.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "Logo_24d28f627e",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 3.58,
        imgurl: gog,
      },
    },
    {
      id: 12,
      url: "https://www.instant-gaming.com/en/",
      color: "#ff5400",
      logo: {
        name: "Store.svg",
        width: 50,
        height: 50,
        formats: null,
        hash: "Store_592bf43862",
        ext: ".svg",
        mime: "image/svg+xml",
        size: 3.42,
        imgurl: instant,
      },
    },
  ];

export default items;